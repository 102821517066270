import React from "react"
import Pagination from "react-bootstrap/Pagination"
import PropTypes from "prop-types"
import Link from "./link"

const NewsPagination = (props) => (
  <div>
    <Pagination>
      {Array.from({ length: props.noOfPages }).map((_, index) => {
        const pageNo = index + 1;
        return (
          <li key={`news-${pageNo}`} className={`page-item ${pageNo === props.activePage ? 'active' : ''}`}>
            <Link to={pageNo === 1 ? '/news' : `/news/${pageNo}`}
                  className={`page-link`}>
                {pageNo}
            </Link>
          </li>
        );
      })}
    </Pagination>
  </div>
)

NewsPagination.propTypes = {
  activePage: PropTypes.number,
  noOfPages: PropTypes.number,
}

export default NewsPagination;
