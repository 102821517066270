import Nav from "react-bootstrap/Nav"
import Tab from "react-bootstrap/Tab"
import React from "react"
import { globalHistory as history, navigate } from "@reach/router"
import PropTypes from "prop-types"

const PillsNavigation = (props) => {
  const location = props.location;
  const items = props.items;

  const selectTab = async (href) => {
    const { location } = history;
    const prefixedHref = location.pathname.startsWith('/v2') ? `/v2${href}/` : href + '/';
    await navigate(prefixedHref);
  }

  return <Tab.Container onSelect={selectTab}>
    <Nav variant="pills" className={`${props.className} justify-content-center justify-content-sm-start`}>
      {items.map(item => (
        <Nav.Item key={item.href}>
          <Nav.Link eventKey={item.href} className={`${location.pathname.includes(item.href) ? 'active' : ''}`}>
            <h1 className={'mb-0'}>{item.name}</h1>
          </Nav.Link>
        </Nav.Item>
      ))}
    </Nav>
  </Tab.Container>
};


PillsNavigation.propTypes = {
  location: PropTypes.any,
  items: PropTypes.arrayOf(PropTypes.any),
  className: PropTypes.string
}

PillsNavigation.defaultProps = {
  items: [],
  className: ''
}

export default PillsNavigation;


